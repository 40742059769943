/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const BatchPutCheckouts = /* GraphQL */ `
  mutation BatchPutCheckouts($checkouts: [BatchPutCheckoutsInput]) {
    BatchPutCheckouts(checkouts: $checkouts) {
      id
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      licenseCode
      channels
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      checkoutConnectionId
      checkoutTokenId
      checkoutChannelId
      __typename
    }
  }
`;
export const BatchDeleteCheckouts = /* GraphQL */ `
  mutation BatchDeleteCheckouts($ids: [ID]) {
    BatchDeleteCheckouts(ids: $ids)
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      occupied
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      occupied
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      occupied
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCheckoutLog = /* GraphQL */ `
  mutation CreateCheckoutLog(
    $input: CreateCheckoutLogInput!
    $condition: ModelCheckoutLogConditionInput
  ) {
    createCheckoutLog(input: $input, condition: $condition) {
      id
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      duration
      createdAt
      updatedAt
      checkoutLogChannelId
      __typename
    }
  }
`;
export const updateCheckoutLog = /* GraphQL */ `
  mutation UpdateCheckoutLog(
    $input: UpdateCheckoutLogInput!
    $condition: ModelCheckoutLogConditionInput
  ) {
    updateCheckoutLog(input: $input, condition: $condition) {
      id
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      duration
      createdAt
      updatedAt
      checkoutLogChannelId
      __typename
    }
  }
`;
export const deleteCheckoutLog = /* GraphQL */ `
  mutation DeleteCheckoutLog(
    $input: DeleteCheckoutLogInput!
    $condition: ModelCheckoutLogConditionInput
  ) {
    deleteCheckoutLog(input: $input, condition: $condition) {
      id
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      duration
      createdAt
      updatedAt
      checkoutLogChannelId
      __typename
    }
  }
`;
export const createConnection = /* GraphQL */ `
  mutation CreateConnection(
    $input: CreateConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    createConnection(input: $input, condition: $condition) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      name
      websocketConnectionId
      publicIp
      createdAt
      updatedAt
      connectionTokenId
      __typename
    }
  }
`;
export const updateConnection = /* GraphQL */ `
  mutation UpdateConnection(
    $input: UpdateConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    updateConnection(input: $input, condition: $condition) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      name
      websocketConnectionId
      publicIp
      createdAt
      updatedAt
      connectionTokenId
      __typename
    }
  }
`;
export const deleteConnection = /* GraphQL */ `
  mutation DeleteConnection(
    $input: DeleteConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    deleteConnection(input: $input, condition: $condition) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      name
      websocketConnectionId
      publicIp
      createdAt
      updatedAt
      connectionTokenId
      __typename
    }
  }
`;
export const createConnectionLog = /* GraphQL */ `
  mutation CreateConnectionLog(
    $input: CreateConnectionLogInput!
    $condition: ModelConnectionLogConditionInput
  ) {
    createConnectionLog(input: $input, condition: $condition) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      type
      createdAt
      updatedAt
      connectionLogTokenId
      connectionLogConnectionId
      __typename
    }
  }
`;
export const updateConnectionLog = /* GraphQL */ `
  mutation UpdateConnectionLog(
    $input: UpdateConnectionLogInput!
    $condition: ModelConnectionLogConditionInput
  ) {
    updateConnectionLog(input: $input, condition: $condition) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      type
      createdAt
      updatedAt
      connectionLogTokenId
      connectionLogConnectionId
      __typename
    }
  }
`;
export const deleteConnectionLog = /* GraphQL */ `
  mutation DeleteConnectionLog(
    $input: DeleteConnectionLogInput!
    $condition: ModelConnectionLogConditionInput
  ) {
    deleteConnectionLog(input: $input, condition: $condition) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      type
      createdAt
      updatedAt
      connectionLogTokenId
      connectionLogConnectionId
      __typename
    }
  }
`;
export const createLicense = /* GraphQL */ `
  mutation CreateLicense(
    $input: CreateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    createLicense(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLicense = /* GraphQL */ `
  mutation UpdateLicense(
    $input: UpdateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    updateLicense(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLicense = /* GraphQL */ `
  mutation DeleteLicense(
    $input: DeleteLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    deleteLicense(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      name
      address1
      address2
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      name
      address1
      address2
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      name
      address1
      address2
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      email
      phone
      address1
      address2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      email
      phone
      address1
      address2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      email
      phone
      address1
      address2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createToken = /* GraphQL */ `
  mutation CreateToken(
    $input: CreateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    createToken(input: $input, condition: $condition) {
      id
      name
      site {
        id
        name
        address1
        address2
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      tokenSiteId
      owner
      __typename
    }
  }
`;
export const updateToken = /* GraphQL */ `
  mutation UpdateToken(
    $input: UpdateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    updateToken(input: $input, condition: $condition) {
      id
      name
      site {
        id
        name
        address1
        address2
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      tokenSiteId
      owner
      __typename
    }
  }
`;
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken(
    $input: DeleteTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    deleteToken(input: $input, condition: $condition) {
      id
      name
      site {
        id
        name
        address1
        address2
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      tokenSiteId
      owner
      __typename
    }
  }
`;
export const createTokenCreationLog = /* GraphQL */ `
  mutation CreateTokenCreationLog(
    $input: CreateTokenCreationLogInput!
    $condition: ModelTokenCreationLogConditionInput
  ) {
    createTokenCreationLog(input: $input, condition: $condition) {
      id
      name
      userId
      customerId
      siteId
      tokenId
      token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTokenCreationLog = /* GraphQL */ `
  mutation UpdateTokenCreationLog(
    $input: UpdateTokenCreationLogInput!
    $condition: ModelTokenCreationLogConditionInput
  ) {
    updateTokenCreationLog(input: $input, condition: $condition) {
      id
      name
      userId
      customerId
      siteId
      tokenId
      token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTokenCreationLog = /* GraphQL */ `
  mutation DeleteTokenCreationLog(
    $input: DeleteTokenCreationLogInput!
    $condition: ModelTokenCreationLogConditionInput
  ) {
    deleteTokenCreationLog(input: $input, condition: $condition) {
      id
      name
      userId
      customerId
      siteId
      tokenId
      token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTokenLicense = /* GraphQL */ `
  mutation CreateTokenLicense(
    $input: CreateTokenLicenseInput!
    $condition: ModelTokenLicenseConditionInput
  ) {
    createTokenLicense(input: $input, condition: $condition) {
      id
      license {
        id
        name
        code
        createdAt
        updatedAt
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      channels
      channelsInUse
      createdAt
      updatedAt
      tokenLicenseLicenseId
      tokenLicenseTokenId
      owner
      __typename
    }
  }
`;
export const updateTokenLicense = /* GraphQL */ `
  mutation UpdateTokenLicense(
    $input: UpdateTokenLicenseInput!
    $condition: ModelTokenLicenseConditionInput
  ) {
    updateTokenLicense(input: $input, condition: $condition) {
      id
      license {
        id
        name
        code
        createdAt
        updatedAt
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      channels
      channelsInUse
      createdAt
      updatedAt
      tokenLicenseLicenseId
      tokenLicenseTokenId
      owner
      __typename
    }
  }
`;
export const deleteTokenLicense = /* GraphQL */ `
  mutation DeleteTokenLicense(
    $input: DeleteTokenLicenseInput!
    $condition: ModelTokenLicenseConditionInput
  ) {
    deleteTokenLicense(input: $input, condition: $condition) {
      id
      license {
        id
        name
        code
        createdAt
        updatedAt
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      channels
      channelsInUse
      createdAt
      updatedAt
      tokenLicenseLicenseId
      tokenLicenseTokenId
      owner
      __typename
    }
  }
`;
export const createCheckout = /* GraphQL */ `
  mutation CreateCheckout(
    $input: CreateCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    createCheckout(input: $input, condition: $condition) {
      id
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      licenseCode
      channels
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      checkoutConnectionId
      checkoutTokenId
      checkoutChannelId
      __typename
    }
  }
`;
export const updateCheckout = /* GraphQL */ `
  mutation UpdateCheckout(
    $input: UpdateCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    updateCheckout(input: $input, condition: $condition) {
      id
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      licenseCode
      channels
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      checkoutConnectionId
      checkoutTokenId
      checkoutChannelId
      __typename
    }
  }
`;
export const deleteCheckout = /* GraphQL */ `
  mutation DeleteCheckout(
    $input: DeleteCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    deleteCheckout(input: $input, condition: $condition) {
      id
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      licenseCode
      channels
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      checkoutConnectionId
      checkoutTokenId
      checkoutChannelId
      __typename
    }
  }
`;
