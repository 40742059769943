import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CheckoutLogService from '../services/checkout-log';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createCheckoutLog = createAsyncThunk(
  'CHECKOUTLOG/CREATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await CheckoutLogService.create(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getCheckoutLogs = createAsyncThunk(
  'CHECKOUTLOG/GETLIST',
  async (param1, { rejectWithValue }) => {
    try {
      const res = await CheckoutLogService.getList();
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getCheckoutLog = createAsyncThunk(
  'CHECKOUTLOG/GET',
  async (id, { rejectWithValue }) => {
    try {
      const res = await CheckoutLogService.get(id);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const updateCheckoutLog = createAsyncThunk(
  'CHECKOUTLOG/UPDATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await CheckoutLogService.update(data);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteCheckoutLog = createAsyncThunk(
  'CHECKOUTLOG/DELETE',
  async (id, { rejectWithValue }) => {
    try {
      await CheckoutLogService.del(id);
      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

const checkoutLogSlice = createSlice({
  name: 'CHECKOUTLOG',
  initialState,
  extraReducers: {
    [createCheckoutLog.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getCheckoutLogs.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getCheckoutLogs.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getCheckoutLog.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getCheckoutLog.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateCheckoutLog.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteCheckoutLog.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    }
  }
});

const { reducer } = checkoutLogSlice;
export default reducer;
