import React from 'react';
import View from '../../table/view'
import { createConnection, deleteConnection, getConnections, updateConnection } from '../../slices/connections'

const Connection = () => {
  const columnDefs = [
    { field: 'id', headerName: 'Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'token', headerName: 'Token', sortable: true, minWidth: 80 },
    { field: 'name', headerName: 'Name', sortable: true, minWidth: 80 },
    { field: 'websocketConnectionId', headerName: 'Websocket Connection Id', sortable: true, minWidth: 80 },
    { field: 'publicIp', headerName: 'Public IP', sortable: true, minWidth: 80 },
  ];
  return (
    <>
      <View
        name="Connection"
        columnDefs={columnDefs}
        deleteFunction={deleteConnection}
        getFunction={getConnections}
        updateFunction={updateConnection}
        createFunction={createConnection}
        stateSelector={(state) => state.connections}
        addEnabled={false}
        deleteEnabled={false}
      />
    </>
  )
}

export default Connection;
