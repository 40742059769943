import React from 'react';
import View from '../../table/view'
import { createCustomer, deleteCustomer, getCustomers, updateCustomer } from '../../slices/customers'

const Customer = () => {
  const columnDefs = [
    { field: 'id', headerName: 'Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'name', formEnabled: true, headerName: 'Name', sortable: true, minWidth: 80 },
    { field: 'email', formEnabled: true, type: "email", headerName: 'Email', sortable: true, minWidth: 80 },
    { field: 'phone', formEnabled: true, headerName: 'Phone Number', sortable: true, minWidth: 80 },
    { field: 'address1', formEnabled: true, headerName: 'Address 1', sortable: true, minWidth: 80 },
    { field: 'address2', formEnabled: true, headerName: 'Address 2', sortable: true, minWidth: 80 },
    { field: 'createdAt', headerName: 'Created At', sortable: true, minWidth: 210 },
    { field: 'updatedAt', headerName: 'Updated At', sortable: true, minWidth: 100 },
  ];
  return (
    <>
      <View
        name="Customer"
        columnDefs={columnDefs}
        deleteFunction={deleteCustomer}
        getFunction={getCustomers}
        updateFunction={updateCustomer}
        createFunction={createCustomer}
        stateSelector={(state) => state.customers}
        addEnabled={true}
        deleteEnabled={true}
      />
    </>
  )
}

export default Customer;
