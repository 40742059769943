import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TokenLicenseService from '../services/token-license';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createTokenLicense = createAsyncThunk(
  'TOKENLICENSES/CREATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await TokenLicenseService.create(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getTokenLicenses = createAsyncThunk(
  'TOKENLICENSES/GETLIST',
  async (filter, { rejectWithValue }) => {
    try {
      const res = await TokenLicenseService.getList();
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getTokenLicense = createAsyncThunk('TOKENLICENSES/GET', async (id, { rejectWithValue }) => {
  try {
    const res = await TokenLicenseService.get(id);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const updateTokenLicense = createAsyncThunk(
  'TOKENLICENSES/UPDATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await TokenLicenseService.update(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteTokenLicense = createAsyncThunk(
  'TOKENLICENSES/DELETE',
  async (id, { rejectWithValue }) => {
    try {
      await TokenLicenseService.del(id);
      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);
export const getTokenLicensesForTokens = createAsyncThunk(
  'TOKENLICENSES/GETLISTFORTOKENS',
  async (tokens, { rejectWithValue }) => {
    try {
      const res = await TokenLicenseService.getList({
        or: tokens.map(token => {
          return {
            tokenLicenseTokenId: {
              eq: token
            }
          }
        })
      });
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

const tokenLicenseSlice = createSlice({
  name: 'TOKENLICENSE',
  initialState,
  extraReducers: {
    [createTokenLicense.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getTokenLicenses.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getTokenLicenses.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getTokenLicense.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getTokenLicense.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateTokenLicense.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteTokenLicense.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    },
    [getTokenLicensesForTokens.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getTokenLicensesForTokens.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
  }
});

const { reducer } = tokenLicenseSlice;
export default reducer;
