import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../services/user';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createUser = createAsyncThunk('USERS/CREATE', async (data, { rejectWithValue }) => {
  try {
    const res = await UserService.create(data);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const getUsers = createAsyncThunk('USERS/GETLIST', async (param1, { rejectWithValue }) => {
  try {
    return UserService.getList();
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const getUser = createAsyncThunk('USERS/GET', async (id, { rejectWithValue }) => {
  try {
    const res = await UserService.get(id);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const updateUser = createAsyncThunk(
  'USERS/UPDATE',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await UserService.update(id, data);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteUser = createAsyncThunk('USERS/DELETE', async (id, { rejectWithValue }) => {
  try {
    await UserService.del(id);
    return { id };
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

const userSlice = createSlice({
  name: 'USER',
  initialState,
  extraReducers: {
    [createUser.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getUsers.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getUsers.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getUser.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getUser.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateUser.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteUser.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    }
  }
});

const { reducer } = userSlice;
export default reducer;
