import React from 'react';
import View from '../../table/view'
import { createConnectionLog, deleteConnectionLog, getConnectionLogs, updateConnectionLog } from '../../slices/connection-log'

const ConnectionLog = () => {
  const columnDefs = [
    { field: 'id', headerName: 'Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'tokenId', headerName: 'Token', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'connection', headerName: 'Connection', sortable: true, minWidth: 80, checkboxSelection: true },
  ];
  return (
    <>
      <View
        name="Connection Log"
        columnDefs={columnDefs}
        deleteFunction={deleteConnectionLog}
        getFunction={getConnectionLogs}
        updateFunction={updateConnectionLog}
        createFunction={createConnectionLog}
        stateSelector={(state) => state.checkoutLog}
        addEnabled={false}
        deleteEnabled={false}
      />
    </>
  )
}

export default ConnectionLog;
