import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ConnectionService from '../services/connection';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createConnection = createAsyncThunk(
  'CONNECTIONS/CREATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await ConnectionService.create(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getConnections = createAsyncThunk(
  'CONNECTIONS/GETLIST',
  async (param1, { rejectWithValue }) => {
    try {
      const res = await ConnectionService.getList();
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getConnection = createAsyncThunk(
  'CONNECTIONS/GET',
  async (id, { rejectWithValue }) => {
    try {
      const res = await ConnectionService.get(id);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const updateConnection = createAsyncThunk(
  'CONNECTIONS/UPDATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await ConnectionService.update(data);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteConnection = createAsyncThunk(
  'CONNECTIONS/DELETE',
  async (id, { rejectWithValue }) => {
    try {
      await ConnectionService.del(id);
      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

const connectionSlice = createSlice({
  name: 'CONNECTION',
  initialState,
  extraReducers: {
    [createConnection.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getConnections.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getConnections.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getConnection.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getConnection.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateConnection.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteConnection.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    }
  }
});

const { reducer } = connectionSlice;
export default reducer;
