import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CustomerService from '../services/customer';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createCustomer = createAsyncThunk(
  'CUSTOMERS/CREATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await CustomerService.create(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getCustomers = createAsyncThunk(
  'CUSTOMERS/GETLIST',
  async (param1, { rejectWithValue }) => {
    try {
      const res = await CustomerService.getList();
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getCustomer = createAsyncThunk('CUSTOMERS/GET', async (id, { rejectWithValue }) => {
  try {
    const res = await CustomerService.get(id);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const updateCustomer = createAsyncThunk(
  'CUSTOMERS/UPDATE',
  async ( data , { rejectWithValue }) => {
    try {
      const res = await CustomerService.update(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'CUSTOMERS/DELETE',
  async (id, { rejectWithValue }) => {
    try {
      await CustomerService.del(id);
      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

const customerSlice = createSlice({
  name: 'CUSTOMER',
  initialState,
  extraReducers: {
    [createCustomer.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getCustomers.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getCustomers.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getCustomer.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateCustomer.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    }
  }
});

const { reducer } = customerSlice;
export default reducer;
