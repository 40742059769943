/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      occupied
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckoutLog = /* GraphQL */ `
  query GetCheckoutLog($id: ID!) {
    getCheckoutLog(id: $id) {
      id
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      duration
      createdAt
      updatedAt
      checkoutLogChannelId
      __typename
    }
  }
`;
export const listCheckoutLogs = /* GraphQL */ `
  query ListCheckoutLogs(
    $filter: ModelCheckoutLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckoutLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        duration
        createdAt
        updatedAt
        checkoutLogChannelId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnection = /* GraphQL */ `
  query GetConnection($id: ID!) {
    getConnection(id: $id) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      name
      websocketConnectionId
      publicIp
      createdAt
      updatedAt
      connectionTokenId
      __typename
    }
  }
`;
export const listConnections = /* GraphQL */ `
  query ListConnections(
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnectionLog = /* GraphQL */ `
  query GetConnectionLog($id: ID!) {
    getConnectionLog(id: $id) {
      id
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      type
      createdAt
      updatedAt
      connectionLogTokenId
      connectionLogConnectionId
      __typename
    }
  }
`;
export const listConnectionLogs = /* GraphQL */ `
  query ListConnectionLogs(
    $filter: ModelConnectionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        connectionLogTokenId
        connectionLogConnectionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLicense = /* GraphQL */ `
  query GetLicense($id: ID!) {
    getLicense(id: $id) {
      id
      name
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLicenses = /* GraphQL */ `
  query ListLicenses(
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      name
      address1
      address2
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address1
        address2
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      email
      phone
      address1
      address2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        address1
        address2
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($id: ID!) {
    getToken(id: $id) {
      id
      name
      site {
        id
        name
        address1
        address2
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      tokenSiteId
      owner
      __typename
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTokenCreationLog = /* GraphQL */ `
  query GetTokenCreationLog($id: ID!) {
    getTokenCreationLog(id: $id) {
      id
      name
      userId
      customerId
      siteId
      tokenId
      token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTokenCreationLogs = /* GraphQL */ `
  query ListTokenCreationLogs(
    $filter: ModelTokenCreationLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenCreationLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        userId
        customerId
        siteId
        tokenId
        token
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTokenLicense = /* GraphQL */ `
  query GetTokenLicense($id: ID!) {
    getTokenLicense(id: $id) {
      id
      license {
        id
        name
        code
        createdAt
        updatedAt
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      channels
      channelsInUse
      createdAt
      updatedAt
      tokenLicenseLicenseId
      tokenLicenseTokenId
      owner
      __typename
    }
  }
`;
export const listTokenLicenses = /* GraphQL */ `
  query ListTokenLicenses(
    $filter: ModelTokenLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channels
        channelsInUse
        createdAt
        updatedAt
        tokenLicenseLicenseId
        tokenLicenseTokenId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckout = /* GraphQL */ `
  query GetCheckout($id: ID!) {
    getCheckout(id: $id) {
      id
      connection {
        id
        name
        websocketConnectionId
        publicIp
        createdAt
        updatedAt
        connectionTokenId
        __typename
      }
      token {
        id
        name
        active
        createdAt
        updatedAt
        tokenSiteId
        owner
        __typename
      }
      licenseCode
      channels
      channel {
        id
        occupied
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      checkoutConnectionId
      checkoutTokenId
      checkoutChannelId
      __typename
    }
  }
`;
export const listCheckouts = /* GraphQL */ `
  query ListCheckouts(
    $filter: ModelCheckoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        licenseCode
        channels
        createdAt
        updatedAt
        checkoutConnectionId
        checkoutTokenId
        checkoutChannelId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
