import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../table/view'
import { createToken, deleteToken, getTokens, updateToken } from '../../slices/tokens'
import copyToClipboard from '../../utilities/copyToClipboard'

const Token = ({ selectedSites, notifySelection }) => {
  const sites = useSelector((state) => state.sites.items)
  const columnDefs = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      minWidth: 400,
      checkboxSelection: false,
      renderCell: (params) => (
        <>
          <Button
            size="small"
            variant="contained"
            style={{ marginRight: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={() => { copyToClipboard(params.value) }}
          >
            Copy
          </Button>
          {params.value}
        </>
      )
    },
    { field: 'name', headerName: 'Name', formEnabled: true, sortable: true, minWidth: 200 },
    {
      field: 'tokenSiteId',
      headerName: 'Site',
      formEnabled: true,
      type: "singleselect",
      sortable: true,
      minWidth: 80,
      valueOptions: sites.map((item) => item.name),
      valueLookup: sites.map((item) => item.id),
      valueGetter: (value, row) => {
        const site = sites.find((item) => { return item.id == value });
        try {
          return site.name;
        } catch (err) {
          return "Unknown";
        }
      }
    },
    { field: 'active', headerName: 'Active', formEnabled: true, sortable: true, minWidth: 80, type: 'boolean', editable: true }
  ];
  return (
    <>
      <View
        name="Token"
        columnDefs={columnDefs}
        deleteFunction={deleteToken}
        updateFunction={updateToken}
        createFunction={createToken}
        notifySelection={(event) => {
          notifySelection(event);
        }}
        stateSelector={(state) => state.tokens}
        addEnabled={true}
        deleteEnabled={true}
      />
    </>
  )
}

export default Token;
